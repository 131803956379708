import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import Grid from '@mui/material/Unstable_Grid2'
import { DateTime } from 'luxon'
import { ThemedAppLink } from '~/components/AppLink'
import { DEFAULT_LOCALE, DEFAULT_ZONE_NAME, ROUTES } from '~/lib/constants'
import { IMAGE_RESPONSIVE_STYLE, newImageSizesBuilder } from '~/lib/image'
import { BasicPageData } from '~/types/pagedata'

export enum ReviewPageCardVariant {
    Large, // top most
    Medium, // bigger 4 sectioned one
    Small, // smaller 3 sectioned ones
    Mobile, // mobile single item
}

interface Props {
    data: BasicPageData
    variant: ReviewPageCardVariant
    showUpdatedTime: Boolean
}

const VerticalReviewPageCard: React.FC<Props> = ({ data, variant, showUpdatedTime, ...props }) => {
    const { id, lastUpdated, title, metaDescription, mainImage } = data

    let titleVariant = 'h3'
    let descriptionVariant = 'subtitle1'
    let updateTimeVariant = 'subtitle1'
    let buttonMaxWidth = undefined
    let imageSizes = '100vw'

    switch (variant) {
        case ReviewPageCardVariant.Mobile:
            buttonMaxWidth = '90%'
            titleVariant = 'h4'
            descriptionVariant = 'subtitle1'
            updateTimeVariant = 'subtitle2'
            break
        case ReviewPageCardVariant.Medium:
            buttonMaxWidth = 260
            titleVariant = 'h4'
            descriptionVariant = 'subtitle1'
            updateTimeVariant = 'subtitle2'
            imageSizes = '50vw'
            break
        case ReviewPageCardVariant.Small:
            titleVariant = 'h4'
            descriptionVariant = 'subtitle2'
            updateTimeVariant = 'subtitle2'
            imageSizes = '33vw'
            break
    }

    return (
            <Box width={1} marginBottom={4} sx={{ marginTop: { xs: 4, md: 0 }}}>
                <ThemedAppLink href={`${ROUTES.review}/${id}`} puRID={id} aria-label={`Read more about ${title.toLowerCase()}`}>
                    <Image
                        src={mainImage.src}
                        width={mainImage.width}
                        height={mainImage.height}
                        style={IMAGE_RESPONSIVE_STYLE}
                        sizes={imageSizes}
                        alt={title}
                        priority={variant === ReviewPageCardVariant.Large}
                        />
                </ThemedAppLink>
                <Grid container rowSpacing={1} sx={{
                    marginY: 3,
                    padding: 0,
                }}>
                    <Grid xs={12} sm><Typography variant={titleVariant}>{title}</Typography></Grid>
                    {showUpdatedTime && <Grid xs={12} sm={'auto'}>
                        <Typography color={'#A1A1A1'} variant={updateTimeVariant} sx={{ marginTop: { xs: 0, md: 1 }}}>
                            Updated {lastUpdated}
                        </Typography>
                    </Grid>}
                </Grid>
                <Typography variant={descriptionVariant} maxWidth={649}>{metaDescription}</Typography>

                { variant !== ReviewPageCardVariant.Large &&
                    <Box textAlign={variant === ReviewPageCardVariant.Mobile ? 'center' : 'initial'}>
                        <ThemedAppLink href={`${ROUTES.review}/${id}`} puRID={id} aria-label={`Read more about ${title.toLowerCase()}`}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    marginTop: 4,
                                    maxWidth: buttonMaxWidth,
                                }}
                                size="large"
                                fullWidth={variant === ReviewPageCardVariant.Mobile || variant === ReviewPageCardVariant.Medium}
                            >
                                Read more
                            </Button>
                        </ThemedAppLink>
                    </Box>
                }
            </Box>
    )
}

export default VerticalReviewPageCard
