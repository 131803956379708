import React from 'react'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { HorizontalBox } from '~/components/extensions/mui'
import { CategorySectionData } from '~/pages'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemedAppLink } from '~/components/AppLink'
import VerticalReviewPageCard, { ReviewPageCardVariant } from '~/components/review/VerticalReviewPageCard'
import HorizontalReviewPageCard from '~/components/review/HorizontalReviewPageCard'

interface Props {
    data: CategorySectionData
    maxItems: number
    showSubcategories: boolean
}

const HomeCategorySection: React.FC<Props> = ({ data, maxItems, showSubcategories }) => {
    const { categoryMeta, subcategories } = data

    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    })
    const computedMaxItems = isMd ? maxItems : 1

    const reviewData = data.reviewData.slice(0, computedMaxItems)

    let gridLayout = undefined

    let headerSubcategoriesComponent = undefined
    if (showSubcategories && isMd) {
        headerSubcategoriesComponent = <Grid xs sm={'auto'} alignSelf={'center'} mt={1}>
            <HorizontalBox>
                {subcategories.map((it, i) => <ThemedAppLink key={`subcatlink-${i}`} href={`${categoryMeta.slug}/${it.slug}`} puCat={`${categoryMeta.name}/${it.name}`} color={'#000000'} underline={true}>
                    <Typography mr={4}>{it.name}</Typography>
                </ThemedAppLink>)}

                <ThemedAppLink href={categoryMeta.slug} puCat={categoryMeta.name} underline={true} aria-label={`See all ${categoryMeta.name} reviews`}><Typography>See all</Typography></ThemedAppLink>
            </HorizontalBox>
        </Grid>
    } else if (showSubcategories) {
        headerSubcategoriesComponent = <Grid xs sm={'auto'} alignSelf={'center'} mt={0.8}>
            <ThemedAppLink href={categoryMeta.slug} puCat={categoryMeta.name} aria-label={`See all ${categoryMeta.name} reviews`}><Typography textAlign={'right'}>See all</Typography></ThemedAppLink>
        </Grid>
    }

    const headerComponent = <Grid container columnSpacing={{ xs: 1, sm: 3 }} xs={12}>
        <Grid xs sm={'auto'}><Typography variant={'h2'} whiteSpace={'nowrap'}>{categoryMeta.name}</Typography></Grid>
        <Grid xs sm alignSelf={'center'} mt={0.5}>{isMd && <Divider color={'#000000'} sx={{ marginRight: 3 }}/>}</Grid>
        {headerSubcategoriesComponent}
    </Grid>

    const gridSx = {
        marginY: { xs: 3, md: 4 },
    }
    if (reviewData.length === 4) {
        gridLayout = <Grid container sx={gridSx}>
            {headerComponent}
            <Grid md={6} mt={6}>
                <VerticalReviewPageCard data={reviewData[0]} variant={ReviewPageCardVariant.Medium} showUpdatedTime={true}/>
            </Grid>

            <Grid container md rowSpacing={0} mt={6} ml={4}>
                {reviewData.slice(1).map(it => <Grid key={`hozcard-${it.id}`}>
                    <HorizontalReviewPageCard data={it} showUpdatedTime={false}/>
                </Grid>)}
            </Grid>
        </Grid>
    } else if (reviewData.length === 3) {
        gridLayout = <Grid container sx={gridSx} columnSpacing={3}>
            {headerComponent}
            {reviewData.map(it => <Grid md={4} mt={6} key={`vertcard-${it.id}`}>
                <VerticalReviewPageCard data={it} variant={ReviewPageCardVariant.Small} showUpdatedTime={false}/>
            </Grid>)}
        </Grid>
    } else { // one item
        gridLayout = <Grid container sx={gridSx}>
            {headerComponent}
            <Grid marginTop={2}><VerticalReviewPageCard data={reviewData[0]} showUpdatedTime={true} variant={ReviewPageCardVariant.Mobile}/></Grid>
        </Grid>
    }

    return <>
        {gridLayout}
    </>
}

export default HomeCategorySection
