import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemedAppLink } from '~/components/AppLink'
import { ROUTES } from '~/lib/constants'
import { IMAGE_RESPONSIVE_STYLE } from '~/lib/image'
import { BasicPageData } from '~/types/pagedata'

interface Props {
    data: BasicPageData
    showUpdatedTime: Boolean
}

const HorizontalReviewPageCard: React.FC<Props> = ({ data, showUpdatedTime, ...props }) => {
    const { id, lastUpdated, title, metaDescription, mainImage } = data

    return (
        <Grid container width={1} padding={0}>
            <Grid md={'auto'} padding={0}>
                <ThemedAppLink href={`${ROUTES.review}/${id}`} puRID={id} aria-label={`Read more about ${title.toLowerCase()}`}>
                    <Box width={190} height={190}>
                        <Image
                            src={mainImage.src}
                            width={mainImage.width}
                            height={mainImage.height}
                            style={IMAGE_RESPONSIVE_STYLE}
                            alt={title}
                            sizes={'25vw'}
                        />
                    </Box>
                </ThemedAppLink>
            </Grid>

            <Grid md padding={0} marginLeft={4}>
                <Grid container rowSpacing={1} sx={{
                    marginBottom: 2,
                    padding: 0,
                }}>
                    <Grid xs={12} sm><Typography variant={'h5'}>{title}</Typography></Grid>
                    {showUpdatedTime && <Grid xs={12} sm={'auto'}>
                        <Typography color={'#A1A1A1'} variant={'subtitle2'}>
                            Updated {lastUpdated}
                        </Typography>
                    </Grid>}
                </Grid>

                <Typography variant={'subtitle3'}>{metaDescription}</Typography>

                <ThemedAppLink href={`${ROUTES.review}/${id}`} puRID={id} aria-label={`Read more about ${title.toLowerCase()}`}>
                    <Typography mt={2}>Read more</Typography>
                </ThemedAppLink>
            </Grid>
        </Grid>
    )
}

export default HorizontalReviewPageCard
