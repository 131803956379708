import Head from 'next/head'
import React, { useRef } from 'react'
import { getBasicReviewData, getReviewCategories, getReviewIdsByCategory } from '~/lib/review'
import { Box } from '@mui/material'
import Main from 'layouts/Main'
import { AppDataContext, buildNavigationProps, NavigationProps } from '~/lib/contexts/AppContexts'
import { BasicPageData, NameAndSlug } from '~/types/pagedata'
import VerticalReviewPageCard, { ReviewPageCardVariant } from '~/components/review/VerticalReviewPageCard'
import Container from '~/components/Container'
import { includes } from 'lodash'
import HomeCategorySection from '~/components/review/HomeCategorySection'
import { CategoryMetaPageProps, categoryMetaToPageProps, categoryToSlug, getCategoryMeta } from '~/lib/categories'
import seo from '~/lib/seo'
import { removeUndefinesFromPageProps } from '~/lib/helpers'

// TODO featured should be in a feature flag
const featuredId = 'best-4k-tv'

interface Props {
    // allReviews: object[];
    // allReviewTitles: string[];
    navigationProps: NavigationProps
    mainFeaturedData: BasicPageData
    // featuredData: BasicPageData[];
    categorySectionData: CategorySectionData[]
}

const Home: React.FC<Props> = ({ navigationProps, mainFeaturedData, categorySectionData }) => {
    // console.log(reviewCategories)
    // console.log(categorySectionData)

    return (
        <AppDataContext.Provider value={{
            navigationProps,
            subscribeRef: useRef(),
        }}>
        <Main>
            <Head>
                <link rel="canonical" href={seo.homeUrl} />
                <meta name="ir-site-verification-token" value="98820492" content="98820492" />
            </Head>

            <Container>
                <VerticalReviewPageCard data={mainFeaturedData} showUpdatedTime={true} variant={ReviewPageCardVariant.Large}/>
                <Box height={6}></Box>
                <HomeCategorySection data={categorySectionData[0]} maxItems={4} showSubcategories={true}/>
                <HomeCategorySection data={categorySectionData[1]} maxItems={3} showSubcategories={true}/>
                <HomeCategorySection data={categorySectionData[2]} maxItems={4} showSubcategories={true}/>
                <HomeCategorySection data={categorySectionData[3]} maxItems={3} showSubcategories={true}/>
            </Container>

        </Main>
        </AppDataContext.Provider>
    )
}

export default Home

export async function getStaticProps() {
    const mainFeaturedData = getBasicReviewData(featuredId)
    const reviewCategories = getReviewCategories()
    const categorySectionData: CategorySectionData[] = [
        'tech',
        'home',
        'travel',
        'sleep',
    ].map(categoryMetaKey => {
        const reviewIds = getReviewIdsByCategory(categoryMetaKey)
        const chosenReviewIds = []
        while (chosenReviewIds.length < 4) {
            // choose random id until we hit the limit we need
            const i = Math.floor(Math.random() * reviewIds.length)
            const reviewId = reviewIds[i]
            const reviewData = getBasicReviewData(reviewId)
            if (reviewData.cn >= 3 && !includes(chosenReviewIds, reviewId) && reviewData.title.length <= 30) {
                chosenReviewIds.push(reviewId)
            }
        }

        const subcategories = reviewCategories[categoryMetaKey]
        const chosenSubcategories = []
        for (let i = 0; i < subcategories.length; i++) {
            const subcat = subcategories[i]
            if (!includes(chosenSubcategories, subcat) && subcat.length <= 15) {
                chosenSubcategories.push(subcat)
            }
            if (chosenSubcategories.length === 3) {
                break
            }
        }

        const meta = getCategoryMeta(categoryMetaKey)

        return {
            categoryMeta: categoryMetaToPageProps(meta),
            subcategories: chosenSubcategories.map(it => ({ name: it, slug: categoryToSlug(it)})),
            reviewData: chosenReviewIds.map(id => getBasicReviewData(id))
        }
    })

    const props: Props = {
        // allReviewTitles: allReviews.map(it => it.title),
        navigationProps: buildNavigationProps(),
        mainFeaturedData,
        // featuredData: [
        //     getBasicReviewData('best-4k-monitors'),
        //     getBasicReviewData('best-android-phone'),
        //     getBasicReviewData('best-air-purifier'),
        // ],
        categorySectionData,
    }

    return { props: removeUndefinesFromPageProps(props) }
}

export interface CategorySectionData {
    categoryMeta: CategoryMetaPageProps
    subcategories: NameAndSlug[]
    reviewData: BasicPageData[]
}
