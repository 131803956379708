import { LG_MIN_WIDTH_BREAKPOINT, MD_MIN_WIDTH_BREAKPOINT, SM_MIN_WIDTH_BREAKPOINT, XL_MIN_WIDTH_BREAKPOINT, XS_MIN_WIDTH_BREAKPOINT } from '~/lib/constants'

export const IMAGE_RESPONSIVE_STYLE = {
    width: '100%',
    height: 'auto',
}

export const IMAGE_INTRINSIC_STYLE = {
    maxWidth: '100%',
    height: 'auto'
}

export class ImageSizesBuilder {
    private xsVw: number
    private smVw: number
    private mdVw: number
    private lgVw: number
    private xlVw: number

    xs(vw: number): ImageSizesBuilder {
        this.xsVw = vw
        return this
    }

    sm(vw: number): ImageSizesBuilder {
        this.smVw = vw
        return this
    }

    md(vw: number): ImageSizesBuilder {
        this.mdVw = vw
        return this
    }

    lg(vw: number): ImageSizesBuilder {
        this.lgVw = vw
        return this
    }

    xl(vw: number): ImageSizesBuilder {
        this.xlVw = vw
        return this
    }

    private normalize(vw: number): number {
        if (vw > 1) return vw
        return vw * 100
    }

    build(): string {
        const sizes = []
        // let largestSetVwProperty = null
        // if (this.xlVw) largestSetVwProperty = 'xlVw'
        // else if (this.lgVw) largestSetVwProperty = 'lgVw'
        // else if (this.mdVw) largestSetVwProperty = 'mdVw'
        // else if (this.smVw) largestSetVwProperty = 'smVw'
        // else if (this.xsVw) largestSetVwProperty = 'xsVw'
        let smallestSetVwProperty = null
        if (this.xsVw) smallestSetVwProperty = 'xsVw'
        else if (this.smVw) smallestSetVwProperty = 'smVw'
        else if (this.mdVw) smallestSetVwProperty = 'mdVw'
        else if (this.lgVw) smallestSetVwProperty = 'lgVw'
        else if (this.xlVw) smallestSetVwProperty = 'xlVw'

        // starting from largest and move down
        if (this.xlVw) {
            const v = `${this.normalize(this.xlVw)}vw`
            sizes.push(smallestSetVwProperty === 'xlVw' ? v : `${XL_MIN_WIDTH_BREAKPOINT} ${v}`)
        }
        if (this.lgVw) {
            const v = `${this.normalize(this.lgVw)}vw`
            sizes.push(smallestSetVwProperty === 'lgVw' ? v : `${LG_MIN_WIDTH_BREAKPOINT} ${v}`)
        }
        if (this.mdVw) {
            const v = `${this.normalize(this.mdVw)}vw`
            sizes.push(smallestSetVwProperty === 'mdVw' ? v : `${MD_MIN_WIDTH_BREAKPOINT} ${v}`)
        }
        if (this.smVw) {
            const v = `${this.normalize(this.smVw)}vw`
            sizes.push(smallestSetVwProperty === 'smVw' ? v : `${SM_MIN_WIDTH_BREAKPOINT} ${v}`)
        }
        if (this.xsVw) {
            const v = `${this.normalize(this.xsVw)}vw`
            sizes.push(smallestSetVwProperty === 'xsVw' ? v : `${XS_MIN_WIDTH_BREAKPOINT} ${v}`)
        }

        if (sizes.length === 0) {
            return '100vw'
        }
        return sizes.join(', ')
    }
}

export function newImageSizesBuilder(): ImageSizesBuilder {
    return new ImageSizesBuilder()
}
