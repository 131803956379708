import React from 'react'
import { Box, BoxProps } from '@mui/material'

export const HorizontalBox: React.FC<BoxProps> = ({ children, ...rest }) => (
    <Box
        display={'flex'}
        flexDirection={'row'}
        {...rest}>{children}</Box>)

export const FlexBox = HorizontalBox

export const VerticalBox: React.FC<BoxProps> = ({ children, ...rest }) => (
    <Box
        display={'flex'}
        flexDirection={'column'}
        {...rest}>{children}</Box>)
